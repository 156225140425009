import { ChangeDetectionStrategy, Component, ElementRef, inject, isDevMode, OnInit, Renderer2 } from '@angular/core';
import { IconComponent } from './icon/icon.component';

import { IsActiveMatchOptions, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { ChristmasLightsComponent } from '../christmas-lights/christmas-lights.component';
import { environment } from '../environments/environment';
import { EventService } from './event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, RouterLink, RouterOutlet, RouterLinkActive, ChristmasLightsComponent],
})
export class AppComponent implements OnInit {
  private readonly el = inject(ElementRef);
  private readonly renderer = inject(Renderer2);
  protected readonly eventService = inject(EventService);
  protected readonly isDevMode = isDevMode;

  protected readonly activeOptions: { exact: boolean } | IsActiveMatchOptions = {
    paths: 'exact',
    matrixParams: 'ignored',
    fragment: 'ignored',
    queryParams: 'ignored',
  };

  ngOnInit(): void {
    for (const url of environment.preconnect) {
      // Dynamically set preconnect URL
      const linkElement = this.renderer.createElement('link');
      this.renderer.setAttribute(linkElement, 'rel', 'preconnect');
      this.renderer.setAttribute(linkElement, 'href', url);

      // Append the link to the head of the document
      this.renderer.appendChild(this.el.nativeElement.ownerDocument.head, linkElement);
    }
  }
}
