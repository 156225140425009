import { provideHttpClient, withFetch } from '@angular/common/http';
import { ApplicationConfig, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { provideServiceWorker } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideClientHydration } from '@angular/platform-browser';

export function prefersReducedMotion(): boolean {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.matchMedia('(prefers-reduced-motion)').matches;
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately', // no delay -> required for google lighthouse test
    }),
    provideAnimationsAsync(prefersReducedMotion() ? 'noop' : 'animations'),
    provideRouter(routes),
    provideExperimentalZonelessChangeDetection(),
    //provideZoneChangeDetection({ eventCoalescing: true }),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        if (config.width) {
          const before = config.src.split('.').slice(0, -1).join('.');
          const after = config.src.substring(config.src.lastIndexOf('.') + 1, config.src.length);
          return `${before}.${config.width}px.${after}`;
        }
        return `${config.src}`;
      },
    },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        placeholderResolution: 32,
      },
    },
  ],
};
