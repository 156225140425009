import { effect, inject, Injectable, isDevMode, PLATFORM_ID, signal } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private readonly platformId = inject(PLATFORM_ID);
  public readonly currentEvent = signal(this.getEvent());

  protected readonly debugEffect = effect(
    () => isPlatformBrowser(this.platformId) && isDevMode() && console.log('Current Event:', this.currentEvent())
  );

  private getEvent() {
    if (isPlatformBrowser(this.platformId)) {
      const params = new URLSearchParams(window.location.search).get('event');
      const item = window.localStorage.getItem('event');
      const event = params || item;
      if (event === null) {
        if (this.isBetweenDec1AndJan6()) {
          return 'christmas';
        }
      }
      return event;
    } else {
      return null;
    }
  }

  private isBetweenDec1AndJan6(): boolean {
    const today = new Date();
    const currentYear = today.getFullYear();

    // Create Date objects for December 1st and January 6th of the current year
    const dec1 = new Date(currentYear, 11, 1); // 11 = December
    const jan6 = new Date(currentYear, 0, 6); // 0 = January

    // Check if the current date is between Dec 1 and Jan 6 of the current year
    return today >= dec1 || today <= jan6;
  }
}
