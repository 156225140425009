import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'attribution',
    loadComponent: () => import('./attribution/attribution.component').then(m => m.AttributionComponent),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
